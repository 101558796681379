import { computed, inject } from "@angular/core";
import { Injectable, type Signal } from "@angular/core";
import type { Aliases } from "lim-ui";
import { firstValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { LanguageDictionary } from "src/app/languages/types/language.types";
import { litePropsInitializer } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.initializer";
import { LitePropsStorageService } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.storage.service";
import type { LiteProps } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.types";
import type { TaskCard } from "src/app/lite/types/display.types";
import type {
   GroupedTasks,
   GroupedTasksObj,
   SortOption,
   SortPref,
} from "src/app/lite/ui/tasks/tasks-sort/tasks-sort.types";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { LimbleMap } from "src/app/shared/utils/limbleMap";

@Injectable({
   providedIn: "root",
})
export class TasksSortService {
   public sortOptions = computed(() => {
      return this.initSortOptions();
   });

   private readonly lang: Signal<LanguageDictionary | undefined> =
      inject(ManageLang).lang;

   private readonly LOCATION_OPTION: SortOption = {
      langKey: "Location",
      displayName: "locationName",
      sortBy: "locationName",
      icon: "houseChimney",
   };

   private readonly PRIORITY_OPTION: SortOption = {
      langKey: "Priority",
      displayName: "priorityDisplayName",
      sortBy: "priorityDisplayName",
      icon: "circleExclamation",
   };

   private readonly STATUS_OPTION: SortOption = {
      langKey: "Status",
      displayName: "statusDisplayName",
      sortBy: "statusDisplayName",
      icon: "circleNotch",
   };

   private readonly DUE_DATE_OPTION: SortOption = {
      langKey: "DueDate",
      displayName: "dueDateDisplay",
      sortBy: "checklistDueDate",
      icon: "clock",
   };

   private readonly ASSIGNED_TO_OPTION: SortOption = {
      langKey: "AssignedTo",
      displayName: "assignedToDisplayName",
      sortBy: "assignedToDisplayName",
      icon: "user",
   };

   private readonly LATEST_ACTIVITY_OPTION: SortOption = {
      langKey: "LatestActivity",
      displayName: "lastEditedDisplay",
      sortBy: "checklistLastEdited",
      icon: "clockRotateLeft",
   };

   private readonly ASSET_OPTION: SortOption = {
      langKey: "Asset",
      displayName: "assetName",
      sortBy: "assetName",
      icon: "cube",
   };

   private readonly CREATED_DATE_OPTION: SortOption = {
      langKey: "CreatedDate",
      displayName: "createdDateDisplay",
      sortBy: "checklistCreatedDate",
      icon: "calendarPen",
   };

   private readonly SORT_OPTIONS_ARRAY: SortOption[] = [
      this.LOCATION_OPTION,
      this.ASSET_OPTION,
      this.ASSIGNED_TO_OPTION,
      this.LATEST_ACTIVITY_OPTION,
      this.PRIORITY_OPTION,
      this.CREATED_DATE_OPTION,
      this.DUE_DATE_OPTION,
      this.STATUS_OPTION,
   ];

   private readonly litePropsStorageService = inject(LitePropsStorageService);

   public async getSortPrefFromStore(): Promise<SortPref> {
      const sortBy = await firstValueFrom(
         this.litePropsStorageService.getTaskListSortBy$(),
      );
      const sortDir = await firstValueFrom(
         this.litePropsStorageService.getTaskListSortDirection$(),
      );

      return {
         sortBy: sortBy,
         sortDir: sortDir,
      };
   }

   public groupTasks(taskCards: TaskCard[], sortPref: SortPref): GroupedTasks[] {
      const sortOption = this.sortOptions().get(sortPref.sortBy);

      const groupedTasksObj = taskCards.reduce(
         (acc: GroupedTasksObj, taskCard: TaskCard) => {
            const sortByValue = taskCard[sortPref.sortBy];

            // If sortByValue is undefined, do not group the task
            if (sortByValue) {
               if (acc[sortByValue]) acc[sortByValue].tasks.push(taskCard);
               else {
                  let displayNameValue = taskCard[sortOption?.displayName ?? ""];
                  if (!displayNameValue)
                     displayNameValue = this.lang()?.[`No${sortOption?.langKey}`];

                  acc[sortByValue] = {
                     tasks: [taskCard],
                     displayNameValue,
                  };
               }
            }

            return acc;
         },
         {},
      );

      // Convert the grouped task arrays into an array of grouped tasks
      const groupedTasksArray = Object.entries(groupedTasksObj).map(
         ([sortByValue, group]) => ({
            [sortPref.sortBy]: String(sortByValue),
            collapsed: false,
            displayName: group.displayNameValue,
            currentPage: 1,
            tasks: group.tasks,
         }),
      );

      // The orderBy function needs the sort direction
      // to be a string with a leading hyphen for descending order
      const sortByWithDirection =
         sortPref.sortDir === "asc" ? sortPref.sortBy : `-${sortPref.sortBy}`;

      return orderBy(groupedTasksArray, sortByWithDirection) as GroupedTasks[];
   }

   public getIconByGroup(sortPref: SortPref): Aliases {
      const currentIcon: Aliases | undefined = this.sortOptions().get(
         sortPref.sortBy,
      )?.icon;
      return currentIcon ? currentIcon : "circleExclamation";
   }

   public getValidSortBy(sortBind: string): LiteProps["taskListSortBy"] {
      switch (this.removeLeadingHyphen(sortBind)) {
         case "checklistDueDate":
            return this.DUE_DATE_OPTION.sortBy;

         case "priorityLevel":
         case "checklistPriorityDisplay":
            return this.PRIORITY_OPTION.sortBy;

         case "statusSortOrder":
         case "sortOrder":
         case "statusName":
            return this.STATUS_OPTION.sortBy;

         case "checklistLastEdited":
         case "checklistLastEditedDisplay":
            return this.LATEST_ACTIVITY_OPTION.sortBy;

         case "locationName":
            return this.LOCATION_OPTION.sortBy;

         case "assignment":
            return this.ASSIGNED_TO_OPTION.sortBy;

         default:
            return litePropsInitializer.taskListSortBy;
      }
   }

   private initSortOptions(): LimbleMap<string, SortOption> {
      const sortOptions = new LimbleMap<string, SortOption>();
      this.SORT_OPTIONS_ARRAY.forEach((option) => {
         option.title = this.lang()?.[option.langKey] ?? "";
         sortOptions.set(option.sortBy, option);
      });
      return sortOptions;
   }

   private removeLeadingHyphen(str: string): string {
      return str.replace(/^-/, "");
   }
}
